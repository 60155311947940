import type {
  CenterFragment,
  CodeType,
  CustomerTypeFragment,
  OrganizationFragment,
  SubscriptionFragment,
} from "@/generated/client.generated";
import type Joi from "joi";
import type { ReactNode } from "react";

export enum UserJourney {
  Recurring = "Recurring",
  Single = "Single",
  Organization = "Organization",
}

export type DiscountCode = {
  code: string;
  type?: CodeType;
};

export type SelectedFormValues = Array<{
  stepIndex: number;
  name: string;
  shortName?: string;
  value: string;
}>;

export type User = {
  userId?: string;
  password?: string;
  accessToken?: string;
};

export type ContactInfo = {
  firstName: string;
  lastName: string;
  address: string;
  postalCode: string;
  postalAddress: string;
  phone: string;
  email: string;
  gender: string;
  birthdate: string;
  password: string;
  passwordConfirmation: string;
};

export type FormData = {
  customerType?: CustomerTypeFragment;
  accountType?: SubscriptionFragment;
  mainCenter?: CenterFragment;
  user: User;
  selectedValues: SelectedFormValues;
  currentStepIndex: number;
  userJourney: UserJourney;
  organization?: OrganizationFragment;
  contactInfo: ContactInfo;
  onBehalfOf: ContactInfo & {
    otherPayer: boolean;
  };
  summary: {
    termsAndConditions: boolean;
    termsMarketing: boolean;
  };
  //For tilfeller når det er en betaler og en mottaker av medlemskap. OtherUser er mottakeren
  otherUser?: User;
};

export const EMPTY_FORM_DATA: FormData = {
  accountType: undefined,
  contactInfo: {
    address: "",
    birthdate: "",
    email: "",
    firstName: "",
    gender: "",
    lastName: "",
    password: "",
    passwordConfirmation: "",
    phone: "",
    postalAddress: "",
    postalCode: "",
  },
  currentStepIndex: 0,
  customerType: undefined,
  mainCenter: undefined,
  onBehalfOf: {
    address: "",
    birthdate: "",
    email: "",
    firstName: "",
    gender: "",
    lastName: "",
    otherPayer: false,
    password: "",
    passwordConfirmation: "",
    phone: "",
    postalAddress: "",
    postalCode: "",
  },
  otherUser: {
    accessToken: undefined,
    password: undefined,
    userId: undefined,
  },
  userJourney: UserJourney.Recurring,
  selectedValues: [],
  summary: {
    termsAndConditions: false,
    termsMarketing: false,
  },
  user: {
    accessToken: undefined,
    password: undefined,
    userId: undefined,
  },
};

export type FormStep = {
  hideInStepIndicator?: boolean;
  hideSubTitle?: boolean;
  id: string;
  shortTitle?: string;
  title: string;
  component: ReactNode;
  skipIfIsLoggedIn?: boolean;
  validatorSchema?: Joi.Schema;
  loader: ReactNode;
  notEditableInStepIndicator?: boolean;
  showDynamicZone?: boolean;
};
